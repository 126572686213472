import React from "react";
import {Link} from "react-router-dom";

import { NewspaperIcon, HeartIcon, SupportIcon } from '@heroicons/react/outline'
import BibleStudiesImg1 from 'images/bible_studies.jpg';

const supportLinks = [
    {
        name: 'Discover Bible Guides',
        href: 'https://bibleschools.com/courses/1/lessons/1/steps/9?locale=en',
        description:
            'These Bible study guides are simple, easy to use, and answer some of life’s challenging questions, such as: Why does God permit suffering and dying? What is the secret to a happy life? Is there hope for our chaotic world?',
        icon: NewspaperIcon,
    },
    {
        name: 'Teach Me to Pray',
        href: 'https://www.hopechannel.com/au/learn/forgive-to-live',
        description:
            'So you don’t know much about God, but you’re open to finding out. This course helps you understand how to talk to Him, and also how He talks to you.',
        icon: SupportIcon,
    },
    {
        name: 'Forgive to Live',
        href: 'https://www.hopechannel.com/au/learn/forgive-to-live',
        description:
            'Every one of us has a story – a hurtful event perpetrated by someone who mattered in our lives. Dr Tibbits’ groundbreaking research revealed that a failure to forgive creates an inner anger disturbing our emotional, physical, and spiritual well-being. This online course will show you how to forgive – insight by insight, step by step, as Dr Dick Tibbits demonstrates how forgiveness can literally save your life.',
        icon: HeartIcon,
    },
]

export default () => (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white">
            <div className="mt-5 mb-6">
                <nav className="hidden sm:flex" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-4">
                        <li>
                            <div>
                                <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-700">Home</Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                          clipRule="evenodd"/>
                                </svg>
                                <a className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Bible Studies</a>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
            <section className="pb-12" aria-labelledby="quick-links-title">
                <div className="bg-white">
                    {/* Header */}
                    <div className="relative pb-32 bg-gray-800">
                        <div className="absolute inset-0">
                            <img
                                className="w-full h-full object-cover"
                                src={BibleStudiesImg1}
                                alt="Bible Studies Banner Image"
                            />
                            <div className="absolute inset-0 bg-gray-800 mix-blend-multiply" aria-hidden="true" />
                        </div>
                        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">Bible Studies</h1>
                            <p className="mt-6 max-w-3xl text-xl text-gray-300">
                                Looking for a free online Bible study to lead you through God’s Word? Find one in the list below. <br/><br/>

                                As Seventh-day Adventists, our promise is to help our friends understand the Bible to find freedom, healing and hope in Jesus. If you would like to experience this kind of relationship with Jesus and learn more about His plans for you, we’ve selected a variety of Bible studies to get you started.
                            </p>
                        </div>
                    </div>

                    {/* Overlapping cards */}
                    <section
                        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
                        aria-labelledby="contact-heading"
                    >
                        <h2 className="sr-only" id="contact-heading">
                            Contact us
                        </h2>
                        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
                            {supportLinks.map((link) => (
                                <div key={link.name} className="flex flex-col bg-white rounded-2xl shadow-xl">
                                    <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                                        <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                                            <link.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </div>
                                        <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                                        <p className="mt-4 text-base text-gray-500">{link.description}</p>
                                    </div>
                                    <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                                        <a href={link.href} className="text-base font-medium text-indigo-700 hover:text-indigo-600">
                                            Learn more<span aria-hidden="true"> &rarr;</span>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </section>
        </div>
    </div>
);