import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Footer extends Component {
   render() {
       return(
           <footer className="bg-white absolute bottom-0 h-5 w-full">
               <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                   <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                       <div className="px-5 py-2">
                           <Link to="/" className="text-base text-gray-500 hover:text-gray-900">
                               Home
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/live-stream" className="text-base text-gray-500 hover:text-gray-900">
                               Watch Live
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/current-series" className="text-base text-gray-500 hover:text-gray-900">
                               Current Series
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/worship" className="text-base text-gray-500 hover:text-gray-900">
                               Worship
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/bible-studies" className="text-base text-gray-500 hover:text-gray-900">
                               Bible Studies
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/mission-trips" className="text-base text-gray-500 hover:text-gray-900">
                               Mission Trips
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/pathfinders" className="text-base text-gray-500 hover:text-gray-900">
                               Pathfinders
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/food-bank" className="text-base text-gray-500 hover:text-gray-900">
                               Food Bank
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/street-beat" className="text-base text-gray-500 hover:text-gray-900">
                               Street Beat
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <Link to="/find-us" className="text-base text-gray-500 hover:text-gray-900">
                               Find Us
                           </Link>
                       </div>

                       <div className="px-5 py-2">
                           <a href="https://adventistgiving.org/#/org/ANGMBQ/envelope/start" className="text-base text-gray-500 hover:text-gray-900">
                               Donate Now
                           </a>
                       </div>
                   </nav>
                   <div className="mt-8 flex justify-center space-x-6">
                       <a href="http://www.facebook.com/ArvadaAdventistChurch" className="text-gray-400 hover:text-gray-500">
                           <span className="sr-only">Facebook</span>
                           <svg viewBox="0 0 24 24" fill="currentColor" className="flex-none text-blue-500 w-12 h-12">
                               <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/>
                           </svg>
                       </a>

                       <a href="http://www.youtube.com/user/AAChurchCo" className="text-gray-400 hover:text-gray-500">
                           <span className="sr-only">Youtube</span>
                           <svg fill="currentColor" className="flex-none text-red-500 w-12 h-12">
                               <rect width="48" height="48" rx="12"></rect>
                               <path
                                   d="M36.83 18.556c0-2.285-1.681-4.124-3.758-4.124a184.713 184.713 0 00-8.615-.182h-.914c-2.925 0-5.799.05-8.612.183-2.072 0-3.753 1.848-3.753 4.133A75.6 75.6 0 0011 23.99a78.487 78.487 0 00.173 5.429c0 2.285 1.68 4.139 3.753 4.139 2.955.137 5.987.198 9.07.192 3.087.01 6.11-.054 9.069-.193 2.077 0 3.758-1.853 3.758-4.138.121-1.813.177-3.62.172-5.434a73.982 73.982 0 00-.165-5.428zM21.512 28.97v-9.979l7.363 4.987-7.363 4.992z"
                                   fill="currentColor" className="text-red-50" />
                           </svg>
                       </a>
                   </div>
                   <p className="mt-8 text-center text-base text-gray-400">
                       &copy; 2020 Arvada Adventist Church. All rights reserved.
                   </p>
               </div>
           </footer>
       );
   }
}

export default Footer;