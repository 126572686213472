import React from "react";
import {Link} from "react-router-dom";
import Trust from 'images/feature/trust.jpg';
import ProblemSolving from 'images/feature/problem_solving.jpg';
import axios from "axios";

class PastMessages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            past_events: {data: []}
        };
    }

    componentDidMount() {
        axios.get('/sermon_series?type=past')
            .then(response => this.setState({past_events: response.data}));
    }

    render() {
        const {past_events} = this.state;
        const formatDate = (dateString) => {
            const options = { year: "numeric", month: "long", day: "numeric" }
            return new Date(dateString).toLocaleDateString(undefined, options)
        }
        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white">
                    <div className="mt-5 mb-6">
                        <nav className="hidden sm:flex" aria-label="Breadcrumb">
                            <ol className="flex items-center space-x-4">
                                <li>
                                    <div>
                                        <Link to="/"
                                              className="text-sm font-medium text-gray-500 hover:text-gray-700">Home</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="flex-shrink-0 h-5 w-5 text-gray-400"
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                        <a className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Past
                                            Messages</a>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="mt-2 mb-10 md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                                Past Messages
                            </h2>
                        </div>
                    </div>
                    <section className="pb-12" aria-labelledby="quick-links-title">
                        <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                            {past_events["data"].map((event, index) => (
                                    <div key={index} className="rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500">
                                        <div>
                                            <span className="rounded-lg inline-flex p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
                                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                                     viewBox="0 0 24 24"
                                                     stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="mt-8">
                                            <h3 className="text-lg font-medium">
                                                <span className="absolute inset-0" aria-hidden="true"></span>
                                                {event.title}
                                            </h3>
                                            <p>{formatDate(event.event_date)}</p>
                                            <p className="mt-2 text-sm text-gray-500">
                                                {event.content}
                                            </p>
                                        </div>
                                        <span className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"/>
                                            </svg>
                                        </span>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}

export default PastMessages;
