import React from "react";
import { Link } from "react-router-dom";
import Gordon from 'images/staff/gordon2.jpg';
import Milos from 'images/staff/milos.png';
import Theresa from 'images/staff/theresa.jpg';

export default () => (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="bg-white">
            <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                    <div className="space-y-5 sm:space-y-4">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Pastoral Team</h2>
                    </div>
                    <div className="lg:col-span-2">
                        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-4">
                                        <img className="object-cover shadow-lg rounded-lg"
                                             src={Gordon}
                                             alt="Gordon Anic"/>
                                    </div>
                                    <div className="text-lg leading-6 font-medium space-y-1">
                                        <h3>Dr. Gordon Anic</h3>
                                        <p className="text-indigo-600">Lead Pastor</p>
                                    </div>

                                    <ul className="flex space-x-5">
                                        <li>
                                            <a  className="text-gray-400 hover:text-gray-500">
                                                <span className="sr-only">Email</span>
                                                <span>gordon@aachurch.org</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-4">
                                        <img className="object-cover shadow-lg rounded-lg"
                                             src={Milos}
                                             alt="Milos Tomic"/>
                                    </div>
                                    <div className="text-lg leading-6 font-medium space-y-1">
                                        <h3>Milos Tomic</h3>
                                        <p className="text-indigo-600">Assistant Pastor</p>
                                    </div>

                                    <ul className="flex space-x-5">
                                        <li>
                                            <a className="text-gray-400 hover:text-gray-500">
                                                <span className="sr-only">Email</span>
                                                <span>milos@aachurch.org</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="space-y-4">
                                    <div className="aspect-w-3 aspect-h-4">
                                        <img className="object-cover shadow-lg rounded-lg"
                                             src={Theresa}
                                             alt="Theresa Boakye"/>
                                    </div>
                                    <div className="text-lg leading-6 font-medium space-y-1">
                                        <h3>Theresa Boakye</h3>
                                        <p className="text-indigo-600">Worship Coordinator</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
);