import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./layout/Footer";
import FindUs from "./pages/FindUs";
import Header from "./layout/Header";
import SocialMedia from "./pages/SocialMedia";
import CurrentSeries from "./pages/CurrentSeries";
import LiveStream from "./pages/LiveStream";
import PastMessages from "./pages/PastMessages";
import Worship from "./pages/Worship";
import BibleStudies from "./pages/BibleStudies";
import MissionTrips from "./pages/MissionTrips";
import Pastoral from "./pages/staff/Pastoral";
import Elders from "./pages/staff/Elders";
import Administration from "./pages/staff/Administration";
import FoodBank from "./pages/FoodBank";
import StreetBeat from "./pages/StreetBeat";
import PathFinder from "./pages/PathFinder";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="relative min-h-screen bg-white min-h-screen">
                    <Header />
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path="/live-stream" exact component={LiveStream} />
                        <Route path="/current-series" exact component={CurrentSeries} />
                        <Route path="/past-messages" exact component={PastMessages} />
                        <Route path="/social-media" exact component={SocialMedia} />
                        <Route path="/worship" exact component={Worship} />
                        <Route path="/bible-studies" exact component={BibleStudies} />
                        <Route path="/food-bank" exact component={FoodBank} />
                        <Route path="/street-beat" exact component={StreetBeat} />
                        <Route path="/mission-trips" exact component={MissionTrips} />
                        <Route path="/pathfinderS" exact component={PathFinder} />
                        <Route path="/staff/pastoral" exact component={Pastoral} />
                        <Route path="/staff/elders" exact component={Elders} />
                        <Route path="/staff/administration" exact component={Administration} />
                        <Route path="/find-us" exact component={FindUs} />
                    </Switch>
                    <Footer />
                </div>
            </Router>
        );
    }
}

export default App;