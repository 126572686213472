import React from "react";
import axios from "axios"
import {Link} from "react-router-dom";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import ContactusImage from 'images/feature/event6.jpg';

const MapWithAMarker = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: 39.812591535937415, lng: -105.07644719723456 }}
    >
        <Marker
            position={{ lat: 39.812591535937415, lng: -105.07644719723456 }}
        />
    </GoogleMap>
));

class FindUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: ''
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        axios({
            method: "POST",
            url:"/contacts",
            data:  this.state
        }).then((response)=>{
            if (response.data.message === 'Message Delivered Successfully') {
                alert("Message Sent.");
                this.resetForm()
            } else {
                alert("Message failed to send.")
            }
        })
    }

    resetForm(){
        this.setState({name: "", email: "", phone: "", message: ""})
    }

    render(){
        return(
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="relative bg-white">
                    <div className="absolute inset-0">
                        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
                    </div>
                    <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
                        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
                            <div className="max-w-lg mx-auto mb-10">
                                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                                    Get in touch
                                </h2>
                                <p className="mt-3 text-lg leading-6 text-gray-500">
                                    Our doors are open to everyone, come and let us worship together.
                                </p>
                                <dl className="mt-8 text-base text-gray-500">
                                    <div>
                                        <dt className="sr-only">Our Address</dt>
                                        <dd>
                                            <p>7090 W. 64th Ave</p>
                                            <p>Arvada, CO 80003</p>
                                        </dd>
                                    </div>
                                    <div className="mt-6">
                                        <dt className="sr-only">Phone number</dt>
                                        <dd className="flex">
                                            <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                            </svg>
                                            <span className="ml-3">
                +1 (303) 422-2369
              </span>
                                        </dd>
                                    </div>
                                    <div className="mt-3">
                                        <dt className="sr-only">Email</dt>
                                        <dd className="flex">
                                            <svg className="flex-shrink-0 h-6 w-6 text-gray-400"
                                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                            </svg>
                                            <span className="ml-3">
                info@aachurch.org
              </span>
                                        </dd>
                                    </div>
                                </dl>
                                <p className="mt-6 text-base text-gray-500">
                                    Looking for Volunteer Opportunity?
                                    <a className="font-medium text-gray-700 underline"> Send Us A Message</a>.
                                </p>
                            </div>
                            <div className="max-w-lg mx-auto">
                                <MapWithAMarker
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBcGGblKNWjUz8NHBi2eRlL8gsj2oAlYqU&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `400px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                            </div>
                        </div>
                        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                            <div className="overflow-hidden rounded-lg mb-5">
                                <div className="px-4 py-5 sm:p-6">
                                    <img className="object-cover shadow-lg rounded-lg" src={ContactusImage} alt="Contact Us"/>
                                </div>
                            </div>
                            <div className="max-w-lg mx-auto lg:max-w-none">
                                <form onSubmit={this.handleSubmit.bind(this)} method="POST" className="grid grid-cols-1 gap-y-6">
                                    <div>
                                        <label htmlFor="full_name" className="sr-only">Full name</label>
                                        <input type="text" name="name" id="full_name" autoComplete="name"
                                               className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                               placeholder="Full name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="sr-only">Email</label>
                                        <input id="email" name="email" type="email" autoComplete="email"
                                               className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                               placeholder="Email" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
                                    </div>
                                    <div>
                                        <label htmlFor="phone" className="sr-only">Phone</label>
                                        <input type="text" name="phone" id="phone" autoComplete="tel"
                                               className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                               placeholder="Phone" value={this.state.phone} onChange={this.onPhoneChange.bind(this)}/>
                                    </div>
                                    <div>
                                        <label htmlFor="message" className="sr-only">Message</label>
                                        <textarea id="message" name="message" rows="4"
                                                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                                  placeholder="Message" value={this.state.message} onChange={this.onMessageChange.bind(this)}></textarea>
                                    </div>
                                    <div>
                                        <button type="submit"
                                                className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    onNameChange(event) {
        this.setState({name: event.target.value})
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }

    onPhoneChange(event) {
        this.setState({phone: event.target.value})
    }

    onMessageChange(event) {
        this.setState({message: event.target.value})
    }
}
export default FindUs;