import React from "react";
import {Link} from "react-router-dom";
import EnglishWorship from 'images/pages/youthhome.jpg';
import TeenWorship from 'images/pages/teens.jpg';
import ChildrensWorship from 'images/pages/childrens_church.jpg';
import SpanishWorship from 'images/pages/spanish.jpg';
import IndonesianWorship from 'images/pages/indonesian.jpg';


export default () => (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white">
            <div className="mt-5 mb-6">
                <nav className="hidden sm:flex" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-4">
                        <li>
                            <div>
                                <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-700">Home</Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                          clipRule="evenodd"/>
                                </svg>
                                <a className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Worship</a>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
            <section className="pb-12" aria-labelledby="quick-links-title">
                <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                    <div className="absolute inset-0">
                        <div className="bg-white h-1/3 sm:h-2/3"></div>
                    </div>
                    <div className="relative max-w-7xl mx-auto">
                        <div className="text-center mb-9">
                            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                                Our Worship Services
                            </h2>
                        </div>
                        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover" src={EnglishWorship} alt="English Service"/>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <a className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">
                                                English Worship
                                            </p>
                                            <p className="mt-3 text-base text-gray-500">
                                                11.00am in the main sanctuary in the Church building.
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover" src={TeenWorship} alt="Teens Service"/>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <a className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">
                                                Teen Worship
                                            </p>
                                            <p className="mt-3 text-base text-gray-500">
                                                Saturdays 11.00am in the School building.
                                            </p>
                                            <p>
                                                Gen Z Jesus! Through the eyes of young people for the young people. <br/>

                                                If you are a teenager, you’ll find personal understanding of the ol’e faith and spiritual/social connection hub as well.
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover" src={ChildrensWorship} alt="Children's Service"/>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <a className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">
                                                Children's Church
                                            </p>
                                            <p className="mt-3 text-base text-gray-500">
                                                Saturdays 11.00am in the main Church building basement.
                                            </p>
                                            <p>
                                                Yes, Jesus loves me… You know that tune? Everybody does! <br/>

                                                Jesus said to bring the children to him, so that’s what we’re doing. From toddlers to high schoolers, your kids will connect to Jesus in their language. So, bring them to him!
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover" src={IndonesianWorship} alt="Indonesian Service"/>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <a className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">
                                                Indonesian Worship
                                            </p>
                                            <p className="mt-3 text-base text-gray-500">
                                                Saturdays 11.00am in the Gymnasium.
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                <div className="flex-shrink-0">
                                    <img className="h-48 w-full object-cover" src={SpanishWorship} alt="Spanish Service"/>
                                </div>
                                <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                                    <div className="flex-1">
                                        <a className="block mt-2">
                                            <p className="text-xl font-semibold text-gray-900">
                                                Spanish Worship
                                            </p>
                                            <p className="mt-3 text-base text-gray-500">
                                                Saturdays 11.00am in the School building
                                            </p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
);