import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import LiveFrame from "./LiveFrame";
import LiveStreamBanner from 'images/pages/banner_01.jpg';

class LiveStream extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            livestream: {}
        };
    }

    componentDidMount() {
        axios.get('/live_streams')
            .then(response => this.setState({ livestream: response.data }));
    }

    render() {
        const { livestream } = this.state;
        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white">
                    <div className="mt-5 mb-6">
                        <nav className="hidden sm:flex" aria-label="Breadcrumb">
                            <ol className="flex items-center space-x-4">
                                <li>
                                    <div>
                                        <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-700">Home</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                        <a className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Live Stream</a>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="relative bg-gray-800">
                        <div className="absolute mb-8 inset-0">
                            <img
                                className="w-full h-full object-cover"
                                src={livestream['data'] ? (livestream['data']['banner_url'] || LiveStreamBanner) : LiveStreamBanner}
                                alt="Live Stream Banner"
                            />
                            <div className="absolute inset-0 bg-gray-400 mix-blend-multiply" aria-hidden="true" />
                        </div>
                        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                            <h1 className="text-4xl drop-shadow-sm font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
                                {livestream['data'] ? livestream['data']['title'] : 'Worship Service'}
                            </h1>
                            <div className="mt-6 drop-shadow-sm text-xl text-indigo-100 max-w-3xl">
                                <div dangerouslySetInnerHTML={{__html: livestream['data'] ? livestream['data']['description'] : ''}} />
                            </div>
                        </div>
                    </div>
                    <section className="pb-12" aria-labelledby="quick-links-title">
                        <div>
                            <LiveFrame src={livestream['data'] ? livestream['data']['content'] : ''} width="100%" height="600px" />
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
export default LiveStream;
