import React from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {Link, useLocation} from 'react-router-dom';
import {
    AnnotationIcon,
    ChatAlt2Icon,
    ChatAltIcon,
    DocumentReportIcon,
    HeartIcon,
    InboxIcon,
    MenuIcon,
    PencilAltIcon,
    QuestionMarkCircleIcon,
    ReplyIcon,
    SparklesIcon,
    TrashIcon,
    UsersIcon,
    XIcon,
    StatusOnlineIcon,
    CheckCircleIcon,
    FlagIcon,
    ThumbUpIcon,
    GlobeAltIcon,
    InformationCircleIcon,
    BookOpenIcon,
    OfficeBuildingIcon,
    GiftIcon,
    FireIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Logo from 'images/logo.jpg'
import MyLink from './MyLink'

const watch = [
    {
        name: 'Current Series',
        description: 'Upcoming sermons and activities',
        href: '/current-series',
        icon: InboxIcon,
    },
    {
        name: 'Past Messages',
        description: 'Our list of past messages',
        href: '/past-messages',
        icon: AnnotationIcon,
    },
    {
        name: 'Live Streaming',
        description: "Live events on Saturdays",
        href: '/live-stream',
        icon: StatusOnlineIcon
    },
    {
        name: 'Social Media',
        description: "Staying connected and engaging to our community",
        href: '/social-media',
        icon: ChatAlt2Icon,
    },
]

const engage = [
    { name: 'Worship', href: '/worship', icon: FireIcon },
    { name: 'Mission Trips', href: '/mission-trips', icon: GlobeAltIcon },
]
const ministries = [
    { name: 'Bible Studies', href: '/bible-studies', icon: OfficeBuildingIcon },
    { name: 'Food Bank', href: '/food-bank', icon: GiftIcon },
    { name: 'Pathfinders', href: '/pathfinders', icon: FlagIcon },
    { name: 'Street Beat', href: '/street-beat', icon: HeartIcon },
]
const blogPosts = [
    {
        id: 1,
        name: 'Youtube Videos',
        href: 'https://www.youtube.com/watch?v=O9bamSobv9M&list=PLAF80114930DAFCF2',
        preview: 'Church Sermons Playlist',
        imageUrl:
            'https://images.unsplash.com/photo-1558478551-1a378f63328e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2849&q=80',
    },
    {
        id: 2,
        name: 'Photo Gallery',
        href: 'https://www.facebook.com/ArvadaAdventistChurch/photos',
        preview: 'Photos of Church family and Friends',
        imageUrl:
            'https://images.unsplash.com/1/apple-gear-looking-pretty.jpg?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80',
    },
]

const staff = [
    { name: 'Pastoral', description: '', href: '/staff/pastoral' },
    { name: 'Elders', description: '', href: '/staff/elders' },
    { name: 'Administration', description: '', href: '/staff/administration' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    return (
        <div className="bg-white">
            <header>
                <Popover className="relative bg-white">
                    {({ open }) => (
                        <>
                            <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                                <div className="flex justify-start lg:w-0 lg:flex-1">
                                    <Link to="/">
                                        <span className="sr-only">Aravda Adventist Church</span>
                                        <img
                                            className="h-8 w-auto sm:h-10"
                                            src={Logo}
                                            alt="AA Church"
                                        />
                                    </Link>
                                </div>
                                <div className="-mr-2 -my-2 md:hidden">
                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open menu</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                                <Popover.Group as="nav" className="hidden md:flex space-x-10">
                                    <Link to="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                        Home
                                    </Link>

                                    <Popover className="relative">
                                        {({ open }) => (
                                            <>
                                                <Popover.Button
                                                    className={classNames(
                                                        open ? 'text-gray-900' : 'text-gray-500',
                                                        'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                    )}
                                                >
                                                    <span>Watch</span>
                                                    <ChevronDownIcon
                                                        className={classNames(
                                                            open ? 'text-gray-600' : 'text-gray-400',
                                                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </Popover.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 translate-y-1"
                                                    enterTo="opacity-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 translate-y-0"
                                                    leaveTo="opacity-0 translate-y-1"
                                                >
                                                    <Popover.Panel
                                                        static
                                                        className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                                    >
                                                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                                {watch.map((item) => (
                                                                    <Link
                                                                        key={item.name}
                                                                        to={item.href}
                                                                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                                    >
                                                                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 text-white sm:h-12 sm:w-12">
                                                                            <item.icon className="h-6 w-6" aria-hidden="true" />
                                                                        </div>
                                                                        <div className="ml-4">
                                                                            <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                                            <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                                                        </div>
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Popover>

                                    <div className="relative z-10 bg-white">
                                        <div className="max-w-7xl mx-auto flex">
                                            <Popover.Button
                                                className={classNames(
                                                    open ? 'text-gray-900' : 'text-gray-500',
                                                    'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span>Connect</span>
                                                <ChevronDownIcon
                                                    className={classNames(
                                                        open ? 'text-gray-600' : 'text-gray-400',
                                                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                            </Popover.Button>
                                        </div>
                                    </div>


                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 -translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 -translate-y-1"
                                    >
                                        <Popover.Panel static className="absolute z-10 inset-x-0 transform top-20 shadow-lg popover-menu">
                                            <div className="absolute inset-0 flex" aria-hidden="true">
                                                <div className="bg-white w-1/2" />
                                                <div className="bg-gray-50 w-1/2" />
                                            </div>
                                            <div className="relative max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2">
                                                <nav
                                                    className="grid gap-y-10 px-4 py-8 bg-white sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12"
                                                    aria-labelledby="solutions-heading"
                                                >
                                                    <h2 id="solutions-heading" className="sr-only">
                                                        Connection menu
                                                    </h2>
                                                    <div>
                                                        <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">Engagement</h3>
                                                        <ul className="mt-5 space-y-6">
                                                            {engage.map((item) => (
                                                                <li key={item.name} className="flow-root">
                                                                    <Link
                                                                        to={item.href}
                                                                        className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                                                    >
                                                                        <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                                                        <span className="ml-4">{item.name}</span>
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">Ministries</h3>
                                                        <ul className="mt-5 space-y-6">
                                                            {ministries.map((item) => (
                                                                <li key={item.name} className="flow-root">
                                                                    <Link
                                                                        to={item.href}
                                                                        className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-50 transition ease-in-out duration-150"
                                                                    >
                                                                        <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                                                        <span className="ml-4">{item.name}</span>
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </nav>
                                                <div className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
                                                    <div>
                                                        <h3 className="text-sm font-medium tracking-wide text-gray-500 uppercase">From the blog</h3>
                                                        <ul className="mt-6 space-y-6">
                                                            {blogPosts.map((post) => (
                                                                <li key={post.id} className="flow-root">
                                                                    <a
                                                                        href={post.href}
                                                                        className="-m-3 p-3 flex rounded-lg hover:bg-gray-100 transition ease-in-out duration-150"
                                                                    >
                                                                        <div className="hidden sm:block flex-shrink-0">
                                                                            <img className="w-32 h-20 object-cover rounded-md" src={post.imageUrl} alt="" />
                                                                        </div>
                                                                        <div className="min-w-0 flex-1 sm:ml-8">
                                                                            <h4 className="text-base font-medium text-gray-900 truncate">{post.name}</h4>
                                                                            <p className="mt-1 text-sm text-gray-500">{post.preview}</p>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="mt-6 text-sm font-medium">
                                                        <Link to="/blog-posts" className="text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
                                                            View all posts <span aria-hidden="true">&rarr;</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                    <Popover className="relative">
                                        {({ open }) => (
                                            <>
                                                <Popover.Button
                                                    className={classNames(
                                                        open ? 'text-gray-900' : 'text-gray-500',
                                                        'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                    )}
                                                >
                                                    <span>Staff</span>
                                                    <ChevronDownIcon
                                                        className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                                                        aria-hidden="true"
                                                    />
                                                </Popover.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 translate-y-1"
                                                    enterTo="opacity-100 translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 translate-y-0"
                                                    leaveTo="opacity-0 translate-y-1"
                                                >
                                                    <Popover.Panel
                                                        static
                                                        className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0"
                                                    >
                                                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                                {staff.map((item) => (
                                                                    <Link
                                                                        key={item.name}
                                                                        to={item.href}
                                                                        className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                                                                    >
                                                                        <p className="text-base font-medium text-gray-900">{item.name}</p>
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Popover.Panel>
                                                </Transition>
                                            </>
                                        )}
                                    </Popover>
                                    <Link to="/find-us" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                        Find Us
                                    </Link>
                                </Popover.Group>
                                <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                                    <span className="relative inline-flex rounded-md shadow-sm">
                                        <Link to="/live-stream" className="inline-flex items-center px-4 py-2 border border-purple-400 text-base leading-6 font-medium rounded-md text-purple-800 bg-white hover:text-purple-700 focus:border-purple-300 transition ease-in-out duration-150">
                                            Live
                                        </Link>
                                        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75"></span>
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></span>
                                        </span>
                                    </span>
                                    <div className="flex-shrink-0 pl-2">
                                        <a href="https://adventistgiving.org/#/org/ANGMBQ/envelope/start"
                                           className=" transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110 relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span>Donate</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <Transition
                                show={open}
                                as={Fragment}
                                enter="duration-200 ease-out"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Popover.Panel
                                    focus
                                    static
                                    className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                                >
                                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                                        <div className="pt-5 pb-6 px-5">
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <img
                                                        className="h-8 w-auto sm:h-10"
                                                        src={Logo}
                                                        alt="AA Church"
                                                    />
                                                </div>
                                                <div className="-mr-2">
                                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                        <span className="sr-only">Close menu</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </Popover.Button>
                                                </div>
                                            </div>
                                            <div className="mt-6">
                                                <nav className="grid grid-cols-1 gap-7">
                                                    {watch.map((item) => (
                                                        <Popover.Button key={item.name} as={MyLink} name={item.name} url={item.href} Icon={item.icon} href={item.href}>
                                                            {item.name}
                                                        </Popover.Button>
                                                    ))}
                                                    {engage.map((item) => (
                                                        <Popover.Button key={item.name} name={item.name} as={MyLink} url={item.href} Icon={item.icon} href={item.href}>
                                                            {item.name}
                                                        </Popover.Button>
                                                    ))}
                                                    {ministries.map((item) => (
                                                        <Popover.Button key={item.name} as={MyLink} name={item.name} url={item.href} Icon={item.icon} href={item.href}>
                                                            {item.name}
                                                        </Popover.Button>
                                                    ))}
                                                    {staff.map((item) => (
                                                        <Popover.Button key={item.name} as={MyLink} name={item.name} url={item.href} href={item.href}>
                                                            {item.name}
                                                        </Popover.Button>
                                                    ))}
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="py-6 px-5">
                                            <div className="grid grid-cols-2 gap-4">
                                                <Link to="/find-us" className="text-base font-medium text-gray-900 hover:text-gray-700">
                                                    Find Us
                                                </Link>
                                            </div>
                                            <div className="mt-6">
                                                <p className="mt-6 text-center text-base font-medium text-gray-500">
                                                    Adventist Giving &nbsp;
                                                    <a href="https://adventistgiving.org/#/org/ANGMBQ/envelope/start" className="text-gray-900">
                                                        Donate
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </header>
        </div>
    )
}
