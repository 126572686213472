import React from "react";
import Background1 from 'images/feature/background1.png';
import Background2 from 'images/poster_ukraine.png';

import {Link} from "react-router-dom";
import {Fragment} from 'react';
import {Transition} from '@headlessui/react';
import {MenuIcon, XIcon} from '@heroicons/react/outline';
import axios from "axios";
import LiveFrame from "./LiveFrame";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            livestream: {},
            past_stream: {data: []},
            latest_sermon: {}
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        axios({
            method: "POST",
            url: "/notify-us",
            data: this.state
        }).then((response) => {
            if (response.data.message === 'Notification Delivered Successfully') {
                this.resetForm()
            } else {
                alert("Message failed to send.")
            }
        })
    }

    componentDidMount() {
        axios.get('/live_streams')
            .then(response => this.setState({ livestream: response.data }));
        axios.get('/live_streams?recent_past=true')
                .then(response => this.setState({ past_stream: response.data }));
        axios.get('/sermon_series/latest')
            .then(response => this.setState({ latest_sermon: response.data }));
    }

    resetForm() {
        this.setState({email: "", name: ""})
    }

    render() {
        const { livestream } = this.state;
        const { past_stream } = this.state;
        const { latest_sermon } = this.state;
        const formatDate = (dateString) => {
            const options = { year: "numeric", month: "long", day: "numeric" }
            return new Date(dateString).toLocaleDateString(undefined, options)
        }
        return (
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div className="relative bg-gray-800">
                    <section>
                        <div className="relative">
                            <div className="">
                                <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
                                    <div className="absolute inset-0">
                                        <img className="h-full w-full object-cover" src={Background1} alt="Arvada SDA Church"/>
                                        <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
                                    </div>
                                    <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                                        <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                                            <span className="block text-white">WE WELCOME ALL</span>
                                            <span className="block text-indigo-200">Arvada Adventist Church</span>
                                        </h1>
                                        <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">

                                        </p>
                                        <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                                            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                                                <Link to="/live-stream" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8">
                                                    Live Stream
                                                </Link>
                                                <Link to="/worship" className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                                                    Worship
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        {/*<div className="grid grid-rows-1 bg-white grid-flow-col">*/}
                        {/*    <div className="mb-5 xl:pl-8 lg:pl-8 md:pl-8 sm:pl-0">*/}
                        {/*        <a href="https://adventistgiving.org/#/org/ANGMBQ/envelope/start"><img*/}
                        {/*            className="inset-0 w-full h-full object-cover"*/}
                        {/*            src={Background2}*/}
                        {/*            alt="Help for UKRAINE"*/}
                        {/*        /></a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="relative bg-white overflow-hidden">
                            <div className="hidden lg:block lg:absolute lg:inset-0" aria-hidden="true">
                                <svg
                                    className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
                                    width={640}
                                    height={784}
                                    fill="none"
                                    viewBox="0 0 640 784"
                                >
                                    <defs>
                                        <pattern
                                            id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
                                            x={118}
                                            y={0}
                                            width={20}
                                            height={20}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                                  fill="currentColor"/>
                                        </pattern>
                                    </defs>
                                    <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor"/>
                                    <rect x={118} width={404} height={784}
                                          fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"/>
                                </svg>
                            </div>

                            <div className="relative pt-6 pb-16 sm:pb-24 lg:pb-32 lg:ml-10">
                                <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
                                    <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                                        <div
                                            className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                                            <h1>
                                                <span className="mt-1 block text-2xl tracking-tight font-extrabold sm:text-3xl xl:text-4xl">
                                                  <span className="block text-indigo-600">{latest_sermon ? latest_sermon["title"]: ""}</span>
                                                </span>
                                            </h1>
                                            <div className="mt-3 text-base sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                                <div dangerouslySetInnerHTML={{__html: latest_sermon ? latest_sermon['content'] : 'We are always working on being a safe place for all people. Our focus is on becoming better people with the help of Jesus. If you need hope, come and learn with us.  \\n" +\n' +
                                                        '"\\"Be strong and courageous. Do not be afraid; do not be discouraged, for the Lord your God will be with you wherever you go.” Joshua 1:9'}} />
                                            </div>
                                            <p className="pt-4 text-base">
                                                {latest_sermon ? formatDate(latest_sermon["event_date"]) : ""} - <Link to="/current-series"> More </Link>
                                            </p>
                                            <div
                                                className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                                                <p className="text-base font-medium text-gray-900">Get more information
                                                    about
                                                    this Sermon</p>
                                                <form onSubmit={this.handleSubmit.bind(this)} method="POST"
                                                      className="mt-3 sm:flex">
                                                    <label htmlFor="email" className="sr-only">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        id="email"
                                                        required={true}
                                                        className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
                                                        placeholder="Email" value={this.state.email}
                                                        onChange={this.onEmailChange.bind(this)}
                                                    />
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        required={true}
                                                        className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
                                                        placeholder="Name" value={this.state.name}
                                                        onChange={this.onNameChange.bind(this)}
                                                    />
                                                    <button
                                                        type="submit"
                                                        className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                                                    >
                                                        Notify me
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                                            <svg
                                                className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                                                width={640}
                                                height={784}
                                                fill="none"
                                                viewBox="0 0 640 784"
                                                aria-hidden="true"
                                            >
                                                <defs>
                                                    <pattern
                                                        id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                                                        x={118}
                                                        y={0}
                                                        width={20}
                                                        height={20}
                                                        patternUnits="userSpaceOnUse"
                                                    >
                                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200"
                                                              fill="currentColor"/>
                                                    </pattern>
                                                </defs>
                                                <rect y={72} width={640} height={640} className="text-gray-50"
                                                      fill="currentColor"/>
                                                <rect x={118} width={404} height={784}
                                                      fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"/>
                                            </svg>
                                            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                                                <Link
                                                    type="button"
                                                    to="/live-stream"
                                                    className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                >
                                                    <span className="sr-only">Watch our video to learn more</span>
                                                    <iframe width="1190" height="669"
                                                            src={livestream['data'] ? (livestream['data']['content']) : ""}
                                                            title="YouTube video player" frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen></iframe>
                                                    <div
                                                        className="absolute inset-0 w-full h-full flex items-center justify-center"
                                                        aria-hidden="true">
                                                        <svg className="h-20 w-20 text-indigo-500" fill="currentColor"
                                                             viewBox="0 0 84 84">
                                                            <circle opacity="0.9" cx={42} cy={42} r={42} fill="white"/>
                                                            <path
                                                                d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z"/>
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                        <div className="bg-white">
                            <div className="max-w-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Past Events</h2>
                                <p className="mt-4 text-base text-gray-500">
                                    List of most recent Events
                                </p>

                                <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
                                    {past_stream["data"].map((item, index) => (
                                        <div key={index} className="group block">
                                            <div>
                                                <LiveFrame src={item['content']} width="100%" height="600px" />
                                            </div>
                                            <h3 className="mt-4 text-base font-semibold text-gray-900">{item["title"]}</h3>
                                            <div className="mt-2 text-sm text-gray-500">
                                                <div dangerouslySetInnerHTML={{__html: item['description']}} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    onEmailChange(event) {
        this.setState({email: event.target.value})
    }
    onNameChange(event) {
        this.setState({name: event.target.value})
    }
}

export default Home;