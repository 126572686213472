import React from "react";
import {Link} from "react-router-dom";
import { InboxIcon, SparklesIcon } from '@heroicons/react/outline'
import StreetBeat from 'images/pages/street_beat.jpg';

export default () => (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white">
            <div className="mt-5 mb-6">
                <nav className="hidden sm:flex" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-4">
                        <li>
                            <div>
                                <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-700">Home</Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                          clipRule="evenodd"/>
                                </svg>
                                <a className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Serve</a>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="mt-2 mb-10 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                        Street Beat
                    </h2>
                </div>
            </div>
            <section className="pb-12" aria-labelledby="quick-links-title">
                <div className="relative bg-white pt-16 pb-32 overflow-hidden">
                    <div className="mt-24">
                        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                                <div>
                                    <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <SparklesIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                                    </div>
                                    <div className="mt-6">
                                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                                            Street Beat - ministering to homeless
                                        </h2>
                                        <p className="mt-4 text-lg text-gray-500">
                                            “When you did it to the least of these, you did it to me.”
                                            Jesus

                                            That is some serious challenge in a city where homeless population has been defaced and ignored for a long time now. But we can change that. We can help them get off the streets and back to life.
                                            Let’s do that together!
                                        </p>
                                        <div className="mt-6">
                                            <a
                                                href="#"
                                                className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700"
                                            >
                                                Get started
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                                    <img
                                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                        src={StreetBeat}
                                        alt="Street Beat"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
);