import React from "react";
import {Link} from "react-router-dom";
import {HeartIcon} from '@heroicons/react/outline'
export default ({ url, name, Icon=HeartIcon }) => (
    <Link onClick={()=>{
        setTimeout(function (){
            window.location.reload();
        }, 1000)
    }} to={url} className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600 text-white">
            <Icon className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="ml-4 text-base font-medium text-gray-900">{name}</div>
    </Link>
);