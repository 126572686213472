import React from "react";
import {Link} from "react-router-dom";

export default () => (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white">
            <div className="mt-5 mb-6">
                <nav className="hidden sm:flex" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-4">
                        <li>
                            <div>
                                <Link to="/" className="text-sm font-medium text-gray-500 hover:text-gray-700">Home</Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <svg className="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                          clipRule="evenodd"/>
                                </svg>
                                <a className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Social Media</a>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="mt-2 mb-10 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                        Social Media
                    </h2>
                </div>
            </div>
            <section className="pb-12" aria-labelledby="quick-links-title">
            </section>
        </div>
    </div>
);