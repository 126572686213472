import React from "react";
import { Link } from "react-router-dom";
import Debbie from 'images/staff/debbie.jpg';
import Richmond from 'images/staff/richmond.jpg';
import DefaultMale from 'images/staff/default_male.png';
import Eric from 'images/staff/eric.png';
import Pharon from 'images/staff/pharon.png';

export default () => (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12">
                    <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet our Elders</h2>
                    </div>
                    <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
                        <li>
                            <div className="space-y-6">
                                <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                                     src={Debbie}
                                     alt="Debbie Ashton"/>
                                    <div className="space-y-2">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>Debbie Ashton</h3>
                                            <p className="text-indigo-600">Head Elder</p>
                                        </div>
                                    </div>
                            </div>
                        </li>

                        <li>
                            <div className="space-y-6">
                                <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                                     src={DefaultMale}
                                     alt="John Hardin"/>
                                    <div className="space-y-2">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>John Hardin</h3>
                                            <p className="text-indigo-600">Assistant Head Elder</p>
                                        </div>
                                    </div>
                            </div>
                        </li>

                        <li>
                            <div className="space-y-6">
                                <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                                     src={Richmond}
                                     alt="Richmond Boakye"/>
                                    <div className="space-y-2">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>Richmond Boakye</h3>
                                            <p className="text-indigo-600">Elder</p>
                                        </div>
                                    </div>
                            </div>
                        </li>

                        <li>
                            <div className="space-y-6">
                                <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                                     src={Eric}
                                     alt="Eric Baliruno"/>
                                    <div className="space-y-2">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>Eric Baliruno</h3>
                                            <p className="text-indigo-600">Elder</p>
                                        </div>
                                    </div>
                            </div>
                        </li>

                        <li>
                            <div className="space-y-6">
                                <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                                     src={DefaultMale}
                                     alt="George Martinez"/>
                                    <div className="space-y-2">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>Jose Contreras</h3>
                                            <p className="text-indigo-600">Elder</p>
                                        </div>
                                    </div>
                            </div>
                        </li>

                        <li>
                            <div className="space-y-6">
                                <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56"
                                     src={Pharon}
                                     alt="Pharon Sornsuwan"/>
                                    <div className="space-y-2">
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>Pharon Sornsuwan</h3>
                                            <p className="text-indigo-600">Elder</p>
                                        </div>
                                    </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
);