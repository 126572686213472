import React from "react";
import {Link} from "react-router-dom";
import Gordon from 'images/staff/gordon.jpg';
import Milos from 'images/staff/milos.png';
import Jenny from 'images/staff/jenny.png';
import Debbie from 'images/staff/debbie.jpg';
import Richmond from 'images/staff/richmond.jpg';
import DefaultMale from 'images/staff/default_male.png';
import DefaultFemale from 'images/staff/default_female.jpg';
import MedicalMins from 'images/staff/kim-dallum-medical-ministry.jpg';
import CradleRoll from 'images/staff/anessa-valdez-craddle-roll.jpg';
import BettyDana from 'images/staff/Betty-and-Dana.jpg';
import WorshipStudy from 'images/staff/worship-study-edited.jpg';
import SaraiVisual from 'images/staff/sarai-visual-1-edited.jpg';
import Wilson from 'images/staff/wilson-cruz-sound.jpg';
import LiveStreaming from 'images/staff/jose-contreras-jr-live-streaming.jpg';

export default () => (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                    <div>
                        <h2 className="text-3xl font-extrabold text-gray-900">
                            ADMINISTRATION
                        </h2>
                    </div>
                    <div className="mt-12 lg:mt-0 lg:col-span-2">
                        <dl className="space-y-12">
                            <div>
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                </dt>
                                <dd className="mt-2 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-4">
                                        <span className="block">Debbie Ashton – Church Clerk</span>
                                        <span className="block">Jasmina Adamovic – Accounting</span>
                                        <span className="block">Ted McKibben – Facilities</span>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                    <div>
                        <h2 className="text-3xl font-extrabold text-gray-900">
                            MINISTRIES
                        </h2>
                    </div>
                    <div className="mt-12 lg:mt-0 lg:col-span-2">
                        <dl className="space-y-12">
                            <div>
                                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                                <dd className="mt-2 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-4">
                                        <span className="block">Community Services – Jasmina Adamovic</span>
                                        <span className="block">Food Bank – Tina Hardin</span>
                                        <span className="block">Street Beat – Kim Dallum</span>
                                        <span className="block">Medical Ministry – Kim Dallum</span>
                                        <span className="block">Pathfinders – Sue Bargas</span>
                                    </div>
                                </dd>
                                <dd className="mt-2 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-12 mt-12">
                                        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-1 lg:gap-x-8">
                                            <li>
                                                <div className="space-y-4">
                                                    <div className="aspect-w-3 aspect-h-2">
                                                        <img className="object-cover shadow-lg rounded-lg"
                                                             src={MedicalMins}
                                                             alt="Kim Dallum - Medical Ministry"/>
                                                    </div>
                                                    <div className="space-y-2">
                                                        <div className="text-lg leading-6 font-medium space-y-1">
                                                            <h3>Kim Dallum - Medical Ministry</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                    <div>
                        <h2 className="text-3xl font-extrabold text-gray-900">
                            WORSHIP & STUDY
                        </h2>
                    </div>
                    <div className="mt-12 lg:mt-0 lg:col-span-2">
                        <dl className="space-y-12">
                            <div>
                                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                                <dd className="mt-2 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-4">
                                        <span className="block">Children’s Church – 11AM Saturday morning</span>
                                        <span className="block">Kindergarten – Betty Willis, Maria Samaniego</span>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                        <dl className="space-y-12">
                            <div>
                                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                                <dd className="mt-12 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-4">
                                        <span className="block">Juniors – Linda Barron, Sue Bargas, Daniel Samaniego</span>
                                        <span className="block">Teens – Lou Acosta</span>
                                    </div>
                                </dd>
                                <dd className="mt-12 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-12 mt-12">
                                        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-1 lg:gap-x-8">
                                            <li>
                                                <div className="space-y-4">
                                                    <div className="aspect-w-3 aspect-h-2">
                                                        <img className="object-cover shadow-lg rounded-lg"
                                                             src={BettyDana}
                                                             alt="Dana Jensen & Betty Willis – Kindergarten"/>
                                                    </div>
                                                    <div className="space-y-2">
                                                        <div className="text-lg leading-6 font-medium space-y-1">
                                                            <h3>Betty Willis – Kindergarten</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                        <dl className="space-y-12">
                            <div>
                                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                                <dd className="mt-12 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-4">
                                        <span className="block">Adult Sabbath School – 10AM Saturday Morning</span>
                                        <span className="block">Adult Class, school – Debbie Ashton, John Hardin, Mike Starr</span>
                                    </div>
                                </dd>
                                <dd className="mt-12 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-12 mt-12">
                                        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-1 lg:gap-x-8">
                                            <li>
                                                <div className="space-y-4">
                                                    <div className="aspect-w-3 aspect-h-2">
                                                        <img className="object-cover shadow-lg rounded-lg"
                                                             src={WorshipStudy}
                                                             alt="Linda Barron & Fernando Zuniga – Juniors"/>
                                                    </div>
                                                    <div className="space-y-2">
                                                        <div className="text-lg leading-6 font-medium space-y-1">
                                                            <h3>Linda Barron – Juniors</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
                <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                    <div>
                        <h2 className="text-3xl font-extrabold text-gray-900">
                            SOUND, STREAMING AND VISUAL
                        </h2>
                    </div>
                    <div className="mt-12 lg:mt-0 lg:col-span-2">
                        <dl className="space-y-12">
                            <div>
                                <dt className="text-lg leading-6 font-medium text-gray-900"></dt>
                                <dd className="mt-2 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-4">
                                        <span className="block">Sound – Wilson Cruz, Dennis Smith, Alan Quinonez</span>
                                        <span className="block">Live Streaming – Jose Contreras Jr., Josh Munaiseche</span>
                                        <span className="block">Visual – Sarai Gutierez, Geraldine Ellison</span>
                                    </div>
                                </dd>
                                <dd className="mt-12 lg:ml-20 text-base text-gray-500">
                                    <div className="space-y-12 mt-12">
                                        <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-2 lg:gap-x-8">
                                            <li>
                                                <div className="space-y-4">
                                                    <div className="aspect-w-3 aspect-h-2">
                                                        <img className="object-cover shadow-lg rounded-lg"
                                                             src={LiveStreaming}
                                                             alt="Jose Contreras Jr. – Live Streaming"/>
                                                    </div>
                                                    <div className="space-y-2">
                                                        <div className="text-lg leading-6 font-medium space-y-1">
                                                            <h3>Jose Contreras Jr. – Live Streaming</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="space-y-4">
                                                    <div className="aspect-w-3 aspect-h-2">
                                                        <img className="object-cover shadow-lg rounded-lg"
                                                             src={SaraiVisual} alt="Sarai Guttierez – Worship Media"/>
                                                    </div>
                                                    <div className="space-y-2">
                                                        <div className="text-lg leading-6 font-medium space-y-1">
                                                            <h3>Sarai Guttierez – Worship Media</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="space-y-4">
                                                    <div className="aspect-w-3 aspect-h-2">
                                                        <img className="object-cover shadow-lg rounded-lg" src={Wilson}
                                                             alt="JWilson Cruz – Sound"/>
                                                    </div>
                                                    <div className="space-y-2">
                                                        <div className="text-lg leading-6 font-medium space-y-1">
                                                            <h3>Wilson Cruz – Sound</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </div>
);